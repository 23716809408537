import { Avatar, makeStyles, Menu, MenuItem } from '@material-ui/core';
import React from 'react';
import { connect } from "react-redux";

import {
	logout
} from "../actions";

const useStyles = makeStyles((theme) => ({
  	avatar: {
  		color: theme.palette.warning.contrastText,
  		cursor: 'pointer',
    	backgroundColor: theme.palette.warning.main,
	},
}));

function AppBarMe(props) {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
	    setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
	    setAnchorEl(null);
	};

	let avatar = null;
	if(props.me.photo !== undefined) {
		avatar = <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />;
	} else {
		let initials = '';
		if(props.me.first_name !== null && props.me.first_name.length > 0) {
			initials += props.me.first_name[0];
		}

		if(props.me.last_name !== null && props.me.last_name.length > 0) {
			initials += props.me.last_name[0];
		}

		avatar = (
			<Avatar
				aria-controls="me-menu"
				aria-haspopup="true"
				className={classes.avatar}
				elevation={0}
				onClick={handleClick}
			>
				{initials}
			</Avatar>
		);
	}

	return (
		<React.Fragment>
			{avatar}

			<Menu
		        id="me-menu"
		        anchorEl={anchorEl}
		        anchorOrigin={{
				    vertical: 'bottom',
				    horizontal: 'center',
				}}
				getContentAnchorEl={null}
		        keepMounted
		        open={Boolean(anchorEl)}
		        onClose={handleClose}
		    >
		    	<MenuItem onClick={props.logout}>Logout</MenuItem>
		    </Menu>
		</React.Fragment>
	);
}

function mapStateToProps(state) {
	return {
		me: state.me.me,
	}
}

export default connect(mapStateToProps, {
	logout
})(AppBarMe);